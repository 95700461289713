import React, { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import Gateway from './Gateway'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactSwitch from 'react-switch'


export const Sitesettings = () => {
  const [WebTitle, setWebTitle] = useState('')
  const [WebsiteName, setWebName] = useState('')
  const [CompanyName, setCompanyName] = useState('')
  const [CompanyAddress, setCompanyAddress] = useState('')
  const [CompanyMobile, setCompanyMobile] = useState('')
  const [commissionRange1, setCommissionRange1] = useState('')
  const [commissionRange2, setCommissionRange2] = useState('')
  const [commissionRange3, setCommissionRange3] = useState('')
  const [checkedCommison, setCheckedCommision] = useState(true)
  const [referralCommission, setReferralCommission] = useState('')

  const [checkedDeposit, setCheckedDeposit] = useState(true);

  const [checkedWithdrawal, setCheckedWithdrawal] = useState(true);
  const [checkedUpiWithdrawal, setCheckedUpiWithdrawal] = useState(true);
  const [checkedBankWithdrawal, setCheckedBankWithdrawal] = useState(true);

  const [depositlimitMin, setDepositMin] = useState("");
  const [depositlimitMax, setDepositMax] = useState("");

  const [withdrawalLimitMin, setWithdrawalMin] = useState("");
  const [withdrawalLimitMax, setWithdrawalMax] = useState("");
  const [autoWithdrawalLimitMax, setautoWithdrawalMax] = useState("");
  const [withdrawTime, setWithdrawTime] = useState("");



  const [Logo, setLogo] = useState('')
  const [SmallLogo, setSmallLogo] = useState('')

  const [LandingImage1, setLandingImage1] = useState('')
  const [LandingImage2, setLandingImage2] = useState('')
  const [LandingImage3, setLandingImage3] = useState('')
  const [LandingImage4, setLandingImage4] = useState('')

  const [isLandingImage1, issetLandingImage1] = useState(true)
  const [isLandingImage2, issetLandingImage2] = useState(true)
  const [isLandingImage3, issetLandingImage3] = useState(true)
  const [isLandingImage4, issetLandingImage4] = useState(true)

  const [version, setVersion] = useState('')
  const [site_message, setSite_message] = useState('')
  const [battle_message, setBattle_message] = useState('')
  const [battle_message2, setBattle_message2] = useState('')
  const [walletmsg, setWalletmsg] = useState('')
  const [withdrawmsg, setWithdrawmsg] = useState('')

  const [settingId, setSettingId] = useState('')

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  useEffect(() => {
    const data = axios.get(baseUrl + 'settings/data', {}).then((res) => {
      console.log(res.data)
      setSettingId(res.data._id ? res.data._id : '')
      setWebTitle(res.data.WebTitle)
      setWebName(res.data.WebsiteName)
      setCompanyName(res.data.CompanyName)
      setCompanyAddress(res.data.CompanyAddress)
      setCompanyMobile(res.data.CompanyMobile)
      setLogo(res.data.Logo)
      setSmallLogo(res.data.SmallLogo)
      setCommissionRange1(res.data.commissionRange1)
      setCommissionRange2(res.data.commissionRange2)
      setCommissionRange3(res.data.commissionRange3)
      setCheckedCommision(res.data.isReferral)
      setReferralCommission(res.data.referralCommission)


      setLandingImage1(res.data.LandingImage1)
      setLandingImage2(res.data.LandingImage2)
      setLandingImage3(res.data.LandingImage3)
      setLandingImage4(res.data.LandingImage4)
      issetLandingImage1(res.data.isLandingImage1)
      issetLandingImage2(res.data.isLandingImage2)
      issetLandingImage3(res.data.isLandingImage3)
      issetLandingImage4(res.data.isLandingImage4)
      setVersion(res.data.version)
      setSite_message(res.data.site_message)
      setBattle_message(res.data.battle_message)
      setBattle_message2(res.data.battle_message2)
      setWalletmsg(res.data.walletmsg)
      setWithdrawmsg(res.data.withdrawmsg)

      setCheckedDeposit(res.data.isDeposit);
 
      setCheckedWithdrawal(res.data.isWithdrawal);
      setCheckedUpiWithdrawal(res.data.isUpiWithdrawal);
      setCheckedBankWithdrawal(res.data.isBankWithdrawal);

      setDepositMin(res.data.depositlimitMin);
      setDepositMax(res.data.depositlimitMax);

      setWithdrawalMin(res.data.withdrawalLimitMin);
      setWithdrawalMax(res.data.withdrawalLimitMax);
      setautoWithdrawalMax(res.data.autoWithdrawalLimitMax);
      setWithdrawTime(res.data.withdrawTime);
    })
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('settingId', settingId)
    formData.append('WebTitle', WebTitle)
    formData.append('WebsiteName', WebsiteName)
    formData.append('CompanyName', CompanyName)
    formData.append('CompanyAddress', CompanyAddress)
    formData.append('CompanyMobile', CompanyMobile)
    formData.append('commissionRange1', commissionRange1)
    formData.append('commissionRange2', commissionRange2)
    formData.append('commissionRange3', commissionRange3)
    formData.append('isReferral', checkedCommison)
    formData.append('referralCommission', referralCommission)

    formData.append("depositlimitMin", depositlimitMin);
    formData.append("depositlimitMax", depositlimitMax);

    formData.append("withdrawalLimitMin", withdrawalLimitMin);
    formData.append("withdrawalLimitMax", withdrawalLimitMax);

    formData.append("isDeposit", checkedDeposit);

    formData.append("isWithdrawal", checkedWithdrawal);
    formData.append("isUpiWithdrawal", checkedUpiWithdrawal);
    formData.append("isBankWithdrawal", checkedBankWithdrawal);


    formData.append('Logo', Logo)
    formData.append('SmallLogo', SmallLogo)
    formData.append('LandingImage1', LandingImage1)
    formData.append('LandingImage2', LandingImage2)
    formData.append('LandingImage3', LandingImage3)
    formData.append('LandingImage4', LandingImage4)
    formData.append('isLandingImage1', isLandingImage1)
    formData.append('isLandingImage2', isLandingImage2)
    formData.append('isLandingImage3', isLandingImage3)
    formData.append('isLandingImage4', isLandingImage4)
    formData.append('version', version)
    formData.append('site_message', site_message)
    formData.append('battle_message', battle_message)
    formData.append('battle_message2', battle_message2)
    formData.append('walletmsg', walletmsg)
    formData.append('withdrawmsg', withdrawmsg)
    const response = await axios.post(baseUrl + `settings`, formData)
    console.log(response.data.status)
    if (response.data.status === 'success') {
      alert('Settings submitted successfully')
    } else {
      alert('Settings Not Submitted')
    }
  }

  useEffect(() => {
    const Logo1 = document.getElementById('Logo')
    const Logo2 = document.getElementById('SmallLogo')
    const LandingImage1 = document.getElementById('LandingImage1')
    const LandingImage2 = document.getElementById('LandingImage2')
    const LandingImage3 = document.getElementById('LandingImage3')
    const LandingImage4 = document.getElementById('LandingImage4')

    Logo1.onchange = (e) => {
      const [file] = Logo1.files
      setLogo(file)
    }
    Logo2.onchange = (e) => {
      const [file] = Logo2.files
      setSmallLogo(file)
    }
    LandingImage1.onchange = (e) => {
      const [file] = LandingImage1.files
      setLandingImage1(file)
    }
    LandingImage2.onchange = (e) => {
      const [file] = LandingImage2.files
      setLandingImage2(file)
    }
    LandingImage3.onchange = (e) => {
      const [file] = LandingImage3.files
      setLandingImage3(file)
    }
    LandingImage4.onchange = (e) => {
      const [file] = LandingImage4.files
      setLandingImage4(file)
    }
  }, [])

  const handleChangeCommison = val => {
    setCheckedCommision(val)
  }

  const handleChangeDeposit = (val) => {
    setCheckedDeposit(val);
  };

  const handleChangeWithdrawal = (val) => {
    setCheckedWithdrawal(val);
  };
  const handleChangeUpiWithdrawal = (val) => {
    setCheckedUpiWithdrawal(val);
  };
  const handleChangeBankWithdrawal = (val) => {
    setCheckedBankWithdrawal(val);
  };

  return (
    <>
      <div className="other_page_cards_main">
        <h3 className=" my-3 other_page_cards_heading">Website Settings</h3>

        <h4 className=" my-3 other_page_cards_heading_small">UI Settings</h4>
        <form
          onSubmit={handleSubmit}
          method="patch"
          encType="multipart/form-data"
          className="border_bottom"
          // style={{ backgroundColor: 'rgba(0, 27, 11, 0.734)' }}
        >
          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Website Title</label>
              <input
                className="form-control"
                type="text"
                value={WebTitle}
                onChange={(e) => setWebTitle(e.target.value)}
              />
            </div>
            {/* </div>

          <div className="form-row"> */}
            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Website Name</label>
              <input
                className="form-control"
                type="text"
                value={WebsiteName}
                onChange={(e) => setWebName(e.target.value)}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Commpany Name</label>
              <input
                className="form-control"
                type="text"
                value={CompanyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Commpany Address</label>
              <input
                className="form-control"
                type="text"
                value={CompanyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
              />
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Commpany Mobile</label>
              <input
                className="form-control"
                type="text"
                value={CompanyMobile}
                onChange={(e) => setCompanyMobile(e.target.value)}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Right Logo</label>
              <input
                className="form-control"
                type="file"
                name="Logo"
                id="Logo"
              />
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Left Logo</label>
              <input
                className="form-control"
                type="file"
                name="SmallLogo"
                id="SmallLogo"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Game image (1) </label>
              <input
                className="form-control"
                type="file"
                name="LandingImage1"
                id="LandingImage1"
              />
              <select
                className="form-control"
                name=""
                id=""
                value={isLandingImage1}
                onChange={(e) => issetLandingImage1(e.target.value)}
              >
                <option value="true">on</option>
                <option value="false">off</option>
              </select>
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Game image (2)</label>
              <input
                className="form-control"
                type="file"
                name="LandingImage2"
                id="LandingImage2"
              />
              <select
                className="form-control"
                name=""
                id=""
                value={isLandingImage2}
                onChange={(e) => issetLandingImage2(e.target.value)}
              >
                <option value="true">on</option>
                <option value="false">off</option>
              </select>
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Game image (3)</label>
              <input
                className="form-control"
                type="file"
                name="LandingImage3"
                id="LandingImage3"
              />
              <select
                className="form-control"
                name=""
                id=""
                value={isLandingImage3}
                onChange={(e) => issetLandingImage3(e.target.value)}
              >
                <option value="true">on</option>
                <option value="false">off</option>
              </select>
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Game image (2)</label>
              <input
                className="form-control"
                type="file"
                name="LandingImage4"
                id="LandingImage4"
              />
              <select
                className="form-control"
                name=""
                id=""
                value={isLandingImage4}
                onChange={(e) => issetLandingImage4(e.target.value)}
              >
                <option value="true">on</option>
                <option value="false">off</option>
              </select>
            </div>
            {/* </div>

          <div className="form-row"> */}
            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">version</label>

              <input
                className="form-control"
                type="text"
                value={version}
                onChange={(e) => setVersion(e.target.value)}
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Website MSG</label>

              <input
                className="form-control"
                type="text"
                value={site_message}
                onChange={(e) => setSite_message(e.target.value)}
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Battle MSG</label>

              <input
                className="form-control"
                type="text"
                value={battle_message}
                onChange={(e) => setBattle_message(e.target.value)}
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Ludo Classic Battle Msg</label>

              <input
                className="form-control"
                type="text"
                value={battle_message2}
                onChange={(e) => setBattle_message2(e.target.value)}
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Wallet Msg</label>

              <input
                className="form-control"
                type="text"
                value={walletmsg}
                onChange={(e) => setWalletmsg(e.target.value)}
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="WebsiteName">Withdraw Msg</label>

              <input
                className="form-control"
                type="text"
                value={withdrawmsg}
                onChange={(e) => setWithdrawmsg(e.target.value)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-4">
              <button type="submit" className="btn btn-dark">
                submit
              </button>
            </div>
          </div>

          <h4 className="my-3 dashboard_small_heading_dark">deposit</h4>
            <div className="form-row">
              <div className="form-group col-md-6">
                <Card>
                  <Card.Body>
                    <h4 className="dashboard_small_heading_blue">Deposit</h4>
                    <Container>
                      <Row
                        style={{ marginRight: "4.25rem", marginTop: "10px" }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {" "}
                          <ReactSwitch
                            checked={checkedDeposit}
                            onChange={handleChangeDeposit}
                            style={{ display: "none" }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: "4.25rem", marginTop: "10px" }}
                      >
                        <Col sm={6}>Deposit Limit Min</Col>
                        <Col sm={1}>
                          <input
                            type="number"
                            style={{
                              border: "1px Solid Black",
                              borderRadius: "3px",
                            }}
                            value={depositlimitMin}
                            onChange={(e) => setDepositMin(e.target.value)}
                            placeholder="Deposit Limit Min"
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: "4.25rem", marginTop: "10px" }}
                      >
                        <Col sm={6}>Deposit Limit Max</Col>
                        <Col sm={1}>
                          <input
                            type="number"
                            style={{
                              border: "1px Solid Black",
                              borderRadius: "3px",
                            }}
                            value={depositlimitMax}
                            onChange={(e) => setDepositMax(e.target.value)}
                            placeholder="Deposit Limit Max"
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: "4.25rem", marginTop: "10px" }}
                      >
                        <Col sm={6}>
                          <button type="submit" className="btn btn-dark">
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
            </div>

            <h4 className="my-3 dashboard_small_heading_dark">Withdrawal</h4>
            <div className="form-row">
              <div className="form-group col-md-6">
                <Card>
                  <Card.Body>
                    <h4 className="dashboard_small_heading_blue">Withdrawal</h4>
                    <Container>
                      {/* <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          <ReactSwitch
                            checked={checkedWithdrawal}
                            onChange={handleChangeWithdrawal}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row> */}
                      <Row
                        style={{ marginRight: "4.25rem", marginTop: "10px" }}
                      >
                        <Col sm={6}>Upi Withdrawal</Col>
                        <Col sm={2}>
                          <ReactSwitch
                            checked={checkedUpiWithdrawal}
                            onChange={handleChangeUpiWithdrawal}
                            style={{ display: "none" }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: "4.25rem", marginTop: "10px" }}
                      >
                        <Col sm={6}>Bank Withdrawal</Col>
                        <Col sm={2}>
                          <ReactSwitch
                            checked={checkedBankWithdrawal}
                            onChange={handleChangeBankWithdrawal}
                            style={{ display: "none" }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: "4.25rem", marginTop: "10px" }}
                      >
                        <Col sm={6}>Withdrawal Limit Min</Col>
                        <Col sm={1}>
                          <input
                            type="number"
                            value={withdrawalLimitMin}
                            onChange={(e) => setWithdrawalMin(e.target.value)}
                            style={{
                              border: "1px Solid Black",
                              borderRadius: "3px",
                            }}
                            placeholder="Withdrawa Limit Min"
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: "4.25rem", marginTop: "10px" }}
                      >
                        <Col sm={6}>Withdrawal Limit Max</Col>
                        <Col sm={1}>
                          <input
                            type="number"
                            value={withdrawalLimitMax}
                            onChange={(e) => setWithdrawalMax(e.target.value)}
                            style={{
                              border: "1px Solid Black",
                              borderRadius: "3px",
                            }}
                            placeholder="Commission Range1"
                          />
                        </Col>
                      </Row>
                      {/* <Row
                        style={{ marginRight: "4.25rem", marginTop: "10px" }}
                      >
                        <Col sm={6}> Auto Withdrawal Limit</Col>
                        <Col sm={1}>
                          <input
                            type="number"
                            value={autoWithdrawalLimitMax}
                            onChange={(e) =>
                              setautoWithdrawalMax(e.target.value)
                            }
                            style={{
                              border: "1px Solid Black",
                              borderRadius: "3px",
                            }}
                            placeholder="Auto Withdrawal Limit"
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: "4.25rem", marginTop: "10px" }}
                      >
                        <Col sm={6}>Withdrwal Time</Col>
                        <Col sm={1}>
                          <input
                            type="number"
                            onChange={(e) => {
                              const minutes = parseFloat(e.target.value);
                              const milliseconds = minutes * 60 * 1000; // Convert hours to milliseconds
                              setWithdrawTime(milliseconds);
                            }}
                            value={withdrawTime / (60 * 1000)} // Display in hours
                            style={{
                              border: "1px Solid Black",
                              borderRadius: "3px",
                            }}
                            placeholder="Withdrawal Time"
                          />
                        </Col>
                      </Row> */}
                      <Row
                        style={{ marginRight: "4.25rem", marginTop: "10px" }}
                      >
                        <Col sm={6}>
                          <button type="submit" className="btn btn-dark">
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
            </div>


          <h4 className=' my-3 dashboard_small_heading_dark'>
              Referral/commison
            </h4>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      Referral/commison
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={8}>Referral</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedCommison}
                            onChange={handleChangeCommison}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={8}>Referral Commission</Col>
                        <Col sm={1}>
                          <input
                            type='number'
                            value={referralCommission}
                            onChange={e =>
                              setReferralCommission(e.target.value)
                            }
                            style={{
                              border: '1px Solid Black',
                              borderRadius: '3px'
                            }}
                            placeholder='Referral Commission'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={8}>Commission Range1 (%)</Col>
                        <Col sm={1}>
                          <input
                            type='number'
                            value={commissionRange1}
                            onChange={e => setCommissionRange1(e.target.value)}
                            style={{
                              border: '1px Solid Black',
                              borderRadius: '3px'
                            }}
                            placeholder='Commission Range1'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={8}>Commission Range2 (flat rate)</Col>
                        <Col sm={1}>
                          <input
                            type='number'
                            value={commissionRange2}
                            onChange={e => setCommissionRange2(e.target.value)}
                            style={{
                              border: '1px Solid Black',
                              borderRadius: '3px'
                            }}
                            placeholder='Commission Range2'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={8}>Commission Range3 (%)</Col>
                        <Col sm={1}>
                          <input
                            type='number'
                            value={commissionRange3}
                            onChange={e => setCommissionRange3(e.target.value)}
                            style={{
                              border: '1px Solid Black',
                              borderRadius: '3px'
                            }}
                            placeholder='Commission Range3'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
            </div>
        </form>
        <Gateway />
      </div>
    </>
  )
}
